<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="columns">
        <div class="column is-2">
          <b-datepicker
            v-model="fromdate"
            placeholder="ຈາກວັນທີ"
            :locale="this.getDateLocale"
            icon="calendar-today"
            @input="selectFromDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <b-datepicker
            v-model="todate"
            placeholder="ຫາວັນທີ"
            :locale="this.getDateLocale"
            icon="calendar-today"
            @input="selectToDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <button class="button" @click="get()">
            <b-icon icon="magnify" />
          </button>
        </div>
        <div class="column is-3">
          <button
            class="button is-success"
            v-if="getGroup.length > 0"
            @click="savePickedRequest"
          >
            <b-icon icon="check-all" /><span>ບັນທຶກຮັບເຄື່ອງສຳເລັດ</span>
          </button>
        </div>
      </div>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
            label="ວັນທີ"
            field="created_date"
            sortable
            v-slot="props"
          >
            {{ props.row.create_date | date }}
          </b-table-column>
          <b-table-column
            label="ຈຳນວນ"
            field="quantity"
            sortable
            v-slot="props"
          >
            {{ props.row.quantity }}
          </b-table-column>
          <b-table-column
            label="ປະເພດເຄຶ່ອງ"
            field="parceltype.name"
            sortable
            v-slot="props"
            cell-class="has-text-left"
          >
            {{ props.row.parceltype.name }}
          </b-table-column>
          <b-table-column
            label="ຊື່"
            field="customer.name"
            sortable
            v-slot="props"
          >
            {{ props.row.customer.name }}
          </b-table-column>
          <b-table-column
            label="ເບີໂທຜູ້ເອີ້ນ"
            field="customer.tel"
            sortable
            v-slot="props"
          >
            {{ props.row.customer.tel }}
          </b-table-column>
          <b-table-column
            label="ເວລານັດຮັບເຄຶ່ອງ"
            field="pickup_request_time"
            sortable
            v-slot="props"
          >
            {{ props.row.pickup_request_time | datetime }}
          </b-table-column>
          <b-table-column label="ໝາຍເຫດ" field="remark" sortable v-slot="props">
            {{ props.row.remark }}
          </b-table-column>
          <b-table-column
            label="ເລືອກ"
            sortable
            field="requestid"
            v-slot="props"
            cell-class="has-text-left"
          >
            <div class="buttons">
              <b-button
                size="is-small"
                @click="showMaps(props.row)"
                icon-right="google-maps"
              />

              <b-checkbox
                v-model="getGroup"
                :native-value="props.row.requestid"
                type="is-success"
              >
              </b-checkbox>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>

    <div
      class="modal is-active is-large mobile-compact"
      v-if="isShowModal && model"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <button class="delete" aria-label="close" @click="closeModal()" />
          <p class="modal-card-title">ສະຖານທີ່ນັດຮັບເຄື່ງ</p>
        </header>
        <section class="modal-card-body">
          <pre
            style="max-height: 400px"
          ><b>ເວລານັດຮັບເຄື່ອງ: </b>[ {{ model.pickup_request_time | datetime }} ]</pre>

          <GmapMap
            :center="center"
            :zoom="15"
            map-type-id="terrain"
            style="width: 100%; height: 300px"
          >
            <GmapMarker
              :position="position"
              :clickable="true"
              :draggable="true"
            />
          </GmapMap>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import moment from "moment";
export default {
  name: "ReportShipmentsByStatus",
  components: { CardComponent, EmptyTable },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ລາຍການເຄື່ອງນອກສະຖານທີ່ ທີ່ຕ້ອງໄປຮັບ",
      models: [],
      getGroup: [],
      isShowModal: false,
      isLoading: false,
      fromdate: new Date(),
      todate: new Date(),
      model: null,
      center: { lat: 17.979454, lng: 102.638114 },
      position: { lat: 17.979454, lng: 102.638114 },
    };
  },
  mounted() {
    this.get();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    savePickedRequest() {
      axios
        .post(`pickuprequest/pickedup`, { requestids: this.getGroup })
        .then((res) => {
          console.log(res);
          const nModels = this.models.filter(
            (i) => !this.getGroup.includes(i.requestid)
          );
          console.log(nModels);
          this.models = nModels;
          this.getGroup = [];
          console.log(this.getGroup);
        });
    },
    closeModal() {
      this.isShowModal = false;
      this.model = [];
    },
    showMaps(item) {
      this.model = item;
      this.position.lat = item.pickup_lat;
      this.position.lng = item.pickup_lon;
      this.center = this.position;
      this.isShowModal = true;
    },

    get() {
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      axios.get(`pickuprequest/pickinglist/${fdate}/${tdate}`).then((r) => {
        this.models = r;
      });
    },
    selectFromDate(e) {
      console.log(e);
    },
    selectToDate(e) {
      console.log(e);
    },
  },
};
</script>